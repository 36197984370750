import { get } from 'http';
import { defineStore } from 'pinia';

export enum GeoPosition {
  UNDEFINED = 'undefined',
  IS_LOADING = 'is_loading',
  FOUND = 'found',
  NOT_FOUND = 'not_found',
  FAILED = 'failed',
  DENIED = 'denied',
}

export interface UserLocation {
  address: string;
  longitude: string;
  latitude: string;
}

interface UserContextState {
  _currentCarBrand: string;
  _registrationNumber: string;
  _firstName: string;
  _lastName: string;
  _email: string;
  _phoneNumber: string;
  _tableView: boolean;
  _userLocation: UserLocation | null;
  _userDeniedPosition: boolean;
  _geoPositionStatus: GeoPosition;
  _clientIp: undefined | string;
  _isInternalIp: boolean | undefined;
}

const initialState: () => UserContextState = () => {
  return {
    _currentCarBrand: '',
    _registrationNumber: '',
    _firstName: '',
    _lastName: '',
    _email: '',
    _phoneNumber: '',
    _tableView: false,
    _userLocation: null,
    _userDeniedPosition: false,
    _geoPositionStatus: GeoPosition.UNDEFINED,
    _clientIp: undefined,
    _isInternalIp: undefined,
  };
};

export const useUserContextStore = defineStore('userContext', {
  state: initialState,
  persist: {
    storage: persistedState.localStorage,
    afterRestore: (ctx) => {
      // Reset or brand filter might be set by default when user revisits site (MON-8048)
      ctx.store._currentCarBrand = '';
      ctx.store._userLocation = null;
      ctx.store._geoPositionStatus = GeoPosition.UNDEFINED;
      ctx.store._clientIp = undefined;
      ctx.store._isInternalIp = undefined;
    },
  },
  getters: {
    currentCarBrand: (state: UserContextState): string => state._currentCarBrand,
    registrationNumber: (state: UserContextState): string => state._registrationNumber,
    firstName: (state: UserContextState): string => state._firstName,
    lastName: (state: UserContextState): string => state._lastName,
    tableView: (state: UserContextState): boolean => state._tableView,
    email: (state: UserContextState): string => state._email,
    phoneNumber: (state: UserContextState): string => state._phoneNumber,
    userLocation: (state: UserContextState): UserLocation | null => state._userLocation,
    userDeniedPosition: (state: UserContextState): boolean => state._userDeniedPosition,
    geoPositionStatus: (state: UserContextState): GeoPosition => state._geoPositionStatus,
    clientIp: (state: UserContextState): string | undefined => state._clientIp,
    isInternalIp: (state: UserContextState): boolean | undefined => state._isInternalIp,
  },
  actions: {
    setCurrentCarBrand(brand: string): void {
      this._currentCarBrand = brand;
    },
    setCurrentRegistrationNumber(regNr: string): void {
      this._registrationNumber = regNr;
    },
    resetCarBrand(): void {
      this._currentCarBrand = '';
    },
    changeFirstName(firstName: string): void {
      this._firstName = firstName;
    },
    changeLastName(lastName: string): void {
      this._lastName = lastName;
    },
    changeEmail(email: string): void {
      this._email = email;
    },
    changeTableView(): void {
      this._tableView = !this._tableView;
    },
    changePhoneNumber(phoneNumber: string): void {
      this._phoneNumber = phoneNumber;
    },
    resetRegistrationNumber(): void {
      this._registrationNumber = '';
    },
    setClientIp(clientIp: string): void {
      this._clientIp = clientIp;
    },
    setIsInternalIp(isInternalIp: boolean): void {
      this._isInternalIp = isInternalIp;
    },
    setUserLocation(userLocation: UserLocation | null): void {
      this._userLocation = userLocation;
    },
    setUserDeniedPosition(userDeniedPosition: boolean): void {
      this._userDeniedPosition = userDeniedPosition;
    },
    setGeoPositionStatus(geoPositionStatus: GeoPosition): void {
      this._geoPositionStatus = geoPositionStatus;
    },
  },
});
