import { defineStore } from 'pinia';
import type {
  FooterFragmentFragment,
  NavigationFragmentFragment,
  SiteContentQuery,
  SiteContentResource,
  SiteSearchType,
} from '~/types/generated-types';
import cleanDeep from 'clean-deep';

export interface SystemSettings {
  numberRegex: string;
  priceValueRegex: string;
  currencyVisualLabel: string;
  currency: string;
  currencyMonth: string;
}

interface SiteContentState {
  _host: string;
  _siteId: string;
  _indexPrefix: string;
  _siteContent: SiteContentQuery['siteContent'] | null;
  _systemTextLowerCased: Map<string, string>;
  _blockOverrides: object;
}

const initialState: () => SiteContentState = () => {
  return {
    _host: '',
    _indexPrefix: '',
    _siteId: '',
    _siteContent: null,
    _systemTextLowerCased: new Map(),
    _blockOverrides: {},
  };
};

export const useSiteContentStore = defineStore('siteContent', {
  state: initialState,
  getters: {
    isDefined: (state: SiteContentState) => state._siteContent !== null,
    host: (state: SiteContentState): string => state._host,
    systemTextLowerCasedKeys: (state: SiteContentState): Map<string, string> => state._systemTextLowerCased,
    systemSettings: (state: SiteContentState): SystemSettings => {
      return {
        numberRegex: state._siteContent?.general?.numberRegex ?? '',
        priceValueRegex: state._siteContent?.general?.priceValueRegex ?? '',
        currencyVisualLabel: state._siteContent?.general?.currencyVisualLabel ?? '',
        currency: state._siteContent?.general?.currency ?? '',
        currencyMonth: state._siteContent?.general?.currencyMonth ?? '',
      };
    },
    siteTheme: (state: SiteContentState): string => state._siteContent?.general?.siteTheme ?? '',
    siteId: (state: SiteContentState): string | undefined => state._siteId || undefined,
    indexPrefix: (state: SiteContentState): string | undefined => state._indexPrefix || undefined,
    headerScript: (state: SiteContentState): string | undefined => state._siteContent?.general?.headerScript ?? undefined,
    languageCode: (state: SiteContentState): string => state._siteContent?.general?.languageCode ?? '',
    footer: (state: SiteContentState): FooterFragmentFragment | undefined => state._siteContent?.footer ?? undefined,
    branchSalesPersonContactPageUrl: (state: SiteContentState): string | undefined =>
      state._siteContent?.branchSettings?.branchSalesPersonContactPageUrl ?? undefined,
    branchTechnicianPersonContactPageUrl: (state: SiteContentState): string | undefined =>
      state._siteContent?.branchSettings?.branchTechnicianPersonContactPageUrl ?? undefined,
    branchContactPageUrl: (state: SiteContentState): string | undefined =>
      state._siteContent?.branchSettings?.branchContactPageUrl ?? undefined,
    navigation: (state: SiteContentState): NavigationFragmentFragment | undefined => state._siteContent?.navigation ?? undefined,
    navigationPanel: (state: SiteContentState) => state._siteContent?.navigationPanel ?? undefined,
    warningText: (state: SiteContentState): string | undefined => state._siteContent?.general?.warningText ?? undefined,
    showWarningTextGlobally: (state: SiteContentState): boolean => state._siteContent?.general?.showWarningTextGlobally ?? false,
    financePostbackPageUrl: (state: SiteContentState): string | undefined =>
      state._siteContent?.general?.financePostbackPageUrl ?? undefined,
    logoUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.logoUrl ?? undefined,
    logoAlt: (state: SiteContentState): string | undefined => state._siteContent?.general?.logoAlt ?? undefined,
    supportUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.supportUrl ?? undefined,
    bookingPageLinkUrl: (state: SiteContentState): string | undefined =>
      state._siteContent?.branchSettings?.bookingPageLinkUrl ?? undefined,
    favoritesPageUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.favoritesPageUrl ?? undefined,
    searchPageUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.defaultCarSearchUrl ?? undefined,
    blockOverrides: (state: SiteContentState): object => state._blockOverrides,
    siteSearch: (state: SiteContentState): SiteSearchType => state._siteContent?.siteSearch as SiteSearchType,
    defaultBranchSearchUrl: (state: SiteContentState): string => state._siteContent?.general?.defaultBranchSearchUrl ?? '',
    defaultCarSearchUrl: (state: SiteContentState): string => state._siteContent?.general?.defaultCarSearchUrl ?? '',
    pageNotFoundErrorText: (state: SiteContentState): string => state._siteContent?.general?.pageNotFoundErrorText ?? '',
    orderPageUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.orderPageUrl ?? undefined,
    checkoutPageUrl: (state: SiteContentState): string | undefined => state._siteContent?.general?.checkoutPageUrl ?? undefined,
    siteName: (state: SiteContentState): string => state._siteContent?.general?.siteName ?? '',
  },
  actions: {
    changeHost(host: string): void {
      this._host = host;
    },
    changeSiteId(siteId: string) {
      this._siteId = siteId;
    },
    changeIndexPrefix(indexPrefix: string) {
      this._indexPrefix = indexPrefix;
    },
    changeSiteContent(siteContent: SiteContentQuery['siteContent']): void {
      this._siteContent = cleanDeep(siteContent) as SiteContentQuery['siteContent'];
      const { siteId = '', indexPrefix = '' } = this._siteContent?.general ?? {};
      this._siteId = siteId;
      this._indexPrefix = indexPrefix;
    },
    changeResources(resources: SiteContentResource[]): void {
      this._systemTextLowerCased = resources.reduce<Map<string, any>>((total, resource) => {
        total.set(resource.name.toLowerCase(), resource.value);
        return total;
      }, new Map<string, any>());
    },
    changeBlockOverrides(blockOverrides: object) {
      this._blockOverrides = blockOverrides;
    },
  },
});
