import { useRoute } from 'vue-router';
import { useUserContextStore } from '~~/stores/userContext';
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const userContextStore = useUserContextStore();

  if (config.public.datadogEnabled) {
    const datadogIgnoreErrors = config.public?.datadogIgnoreErrors
      .split(',')
      .map((item) => item.trim().replace(/'/g, ''))
      .filter((item) => item !== '');
    const datadogActionUrlSegments = config.public?.datadogActionUrlSegments
      .split(',')
      .map((item) => item.trim().replace(/'/g, ''))
      .filter((item) => item !== '');

    const datadogRum = (await import('@datadog/browser-rum')).datadogRum;
    datadogRum.init({
      applicationId: config.public.datadogApplicationId,
      clientToken: config.public.datadogClientToken,
      site: config.public.datadogSite,
      service: config.public.datadogService,
      env: process.env.NODE_ENV,
      version: config.public.releaseVersion as string,
      sessionSampleRate: Number(config.public?.datadogSampleRate ?? 0),
      sessionReplaySampleRate: 0, //Number(config.public?.datadogReplaySampleRate ?? 0),
      trackResources: false,
      trackLongTasks: false,
      trackUserInteractions: (config?.public.datadogTrackUserInteractions as boolean) ?? false,
      enablePrivacyForActionName: config.public.datadogMaskAll,
      beforeSend: (event) => {
        // Don't send internal IP addresses
        if (config.public.datadogIgnoreInternalIp && userContextStore.isInternalIp) {
          return false;
        }

        if (event.type === 'error' && datadogIgnoreErrors.includes(event.error.message)) {
          return false;
        }
        // send Action events only for specific URLs or custom actions
        if (event.type === 'action') {
          if (
            event.action.type == 'custom' ||
            (datadogActionUrlSegments.length > 0 && datadogActionUrlSegments.some((substring) => event.view.url.includes(substring)))
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      },
    });
    if (Number(config.public?.datadogReplaySampleRate ?? 0) > 0) {
      datadogRum.startSessionReplayRecording(); // Used for session replays
    }
  }
});
