<template>
  <NuxtLayout :name="layout">
    <NuxtPage :use-fake-modal="onlyShowBody" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts';
import { useSiteContentStore } from './stores/siteContent';
import { TrackJS } from 'trackjs';
import type { SiteContentQuery } from '~/types/generated-types';

const nuxtApp = useNuxtApp();
const config = useRuntimeConfig();
const route = useRoute();
const siteContent = useSiteContentStore();
const layout = ref<LayoutKey>('layout');

const handleError = (statusCode: number, message: string): void => {
  const error = { message: message, statusCode: statusCode };
  let isValidError = true;

  if (route.path.indexOf('_nuxt') >= 0) isValidError = false;
  if (route.path.endsWith('svg')) isValidError = false;

  if (isValidError) {
    if (import.meta.client) {
      showError(error);
    } else {
      throw createError(error);
    }
  }
};

const fetchSite = (): Promise<SiteContentQuery> => {
  return nuxtApp.$siteContentRepository.fetchSiteContent({
    host: siteContent.host,
    previewindex: config.public.previewIndex,
    siteId: config.public.siteId || '',
    indexPrefix: siteContent.indexPrefix ?? '',
    withNavigationPanel: config.public.withNavigationPanel,
  });
};

if (!siteContent.isDefined) {
  const res = await fetchSite();

  if (res.siteContent) {
    siteContent.changeSiteContent({ ...res.siteContent, resources: undefined });

    if (res.siteContent.resources) siteContent.changeResources(res.siteContent.resources);
  } else {
    TrackJS.track('Site content is missing');
    handleError(500, 'Site content is missing');
  }
}

// https://biliaab.atlassian.net/browse/MON-8602
// POC for only showing body content for lexus showroom touchscreens.
const { systemText, systemTextExists } = useSystemText();

const pocTouchScreenFeatureFlag = ref((await nuxtApp.$getFeatureFlag('poctouchscreen')) ?? false);

const pocSystemText = systemTextExists('pocLexusUrl') ? systemText('pocLexusUrl') : undefined;

const onlyShowBody = ref(
  pocTouchScreenFeatureFlag.value ? (pocSystemText ? route.fullPath.includes(pocSystemText) ?? false : false) : false
);

if (pocTouchScreenFeatureFlag.value && config.public.lexusPoc) {
  if (pocSystemText && route.fullPath.includes(pocSystemText)) {
    layout.value = 'poc-lexus';
  }

  watch(
    () => route.fullPath,
    () => {
      if (pocSystemText && route.fullPath.includes(pocSystemText)) {
        onlyShowBody.value = true;
        layout.value = 'poc-lexus';
      } else {
        onlyShowBody.value = false;
        if (layout.value !== 'layout') layout.value = 'layout';
      }
    }
  );
}
</script>

<style lang="postcss">
#__nuxt {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  > main {
    flex: 1;
  }
}
</style>
